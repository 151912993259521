<template>
  <div id="Extension">
    <header class="imgination">
      <img src="logo_border.png" />
    </header>
    <SiteNav></SiteNav>
    <div class="container text-center">
      <p class="lead"><b>
        Installiere Dir die offizielle<br /><b
          ></b
        ></b>
      </p>
      <h1 class="brand-color-dark">StadtbahnfahrerClub<br>Browsererweiterung</h1>
      <br />
      <img
        src="https://storage.googleapis.com/chrome-gcs-uploader.appspot.com/image/WlD8wC6g8khYWPJUsQceQkhXSlv1/mPGKYBIR2uCP0ApchDXE.png"
      />
      <br /><br /><br />
      <p>
        Die StadtbahnfahrerClub Browsererweiterung ist kostenfrei im Chrome Web
        Store erhältlich.<br />Kompatibel am PC und Mac mit Google Chrome, Microsoft Edge und Firefox.
      </p>
      <p>
        Die Browsererweiterung stellt die wichtigste  Verbindung zwischen Deinen Dienstdaten und unserer Plattform her.
      </p>
      
        <h4>Was ist eine Browsererweiterung?</h4>
        <p>
        Eine Browsererweiterung kann die Funktionalität Deines Internetbrowsers erweitern und Dein Nutzererlebnis auf Internetseiten verändern.
        </p>
      <div class="flex">
        <div>
        <h4>Was kann die StadtbahnfahrerClub Erweiterung?</h4>
        <p>
Unsere Browsererweiterung kann das Grundkonstrukt Deines Dienstplans auslesen, wenn Du Dich auf der jeweilen Seite befindest. 
<ul>
  <li>Du hast die Möglichkeit einen automatisch Modus zu starten, in dem die Browsererweiterung automatisch den aktuellen Monat ausliest und an unsere Plattform überträgt.</li>
  <li>Zusätzlich wertet die Erweiterung einzelne Dienstpläne automatisch aus und überträgt sie unsere Plattform, wenn Du sie öffnest.</li>
  <li>Die Browsererweiterung passt das optische Aussehen an.</li>
</ul>
</p>
      </div>
      <div>
    <h4>Was kann die StadtbahnfahrerClub Erweiterung <u>nicht</u>?</h4>
        <p>
Wir möchten ausdrücklich darauf hinweisen, dass unsere Erweiterung nur begrenzte technische Fähigkeiten hat und nur die Daten erfassen kann, die Du menschlich selber im Internetbrowser sehen kannst. 
<ul>
  <li>Unsere Erweiterung kann nur Daten erfassen, wenn Du Dich aktiv auf der Internetseite für Deinen Dienstplan befindest. </li>
  <li>Sie kann nicht automatisch im Hintergrund arbeiten und auf die Server Deiner Verkehrsgesellschaft zugreifen. </li>
  <li>Wir haben keinen Zugriff auf Deinen Browserverlauf, Deine Zugangsdaten oder ähnliches. </li></ul></p>
      </div></div>
      
    <p>Die StadtbahnfahrerClub Browsererweiterung ist mit den gängigen Internetbrowsern Microsoft Edge, Google Chrome und Firefox auf PC und Mac kompatible. 
Mit unserer Browsererweiterung können wir Kolleginnen und Kollegen unabhängig der Verkehrsgesellschaft zeitnah einen Zugang zum StadtbahnfahrerClub ermöglichen - unabhängig welche Software die Verkehrsgesellschaft einsetzt. </p>
    <p>Wenn Dir der StadtbahnfahrerClub gefällt, teile ihn bitte mit Deinen Kolleginnen und Kollegen. Nur gemeinsam können wir die Plattform der Zukunft auch in Deine Verkehrsgesellschaft vollständig integrieren. </p>
    </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";

import { mapGetters } from "vuex";

export default {
  name: "Extension",
  components: {
    SiteNav,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>